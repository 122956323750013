exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-blog-css-style-checkboxes-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/blog/css-style-checkboxes/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-blog-css-style-checkboxes-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-blog-golang-rest-api-embed-parameter-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/blog/golang-rest-api-embed-parameter/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-blog-golang-rest-api-embed-parameter-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-devmarks-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/devmarks/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-devmarks-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-grindlists-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/grindlists/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-grindlists-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-hackernews-clone-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/hackernews-clone/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-hackernews-clone-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-leopod-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/leopod/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-leopod-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-qr-gen-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/qr-gen/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-qr-gen-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-ship-of-harkinian-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/ship-of-harkinian/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-ship-of-harkinian-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-space-game-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/space-game/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-space-game-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-todo-vite-ts-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/todo-vite-ts/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-todo-vite-ts-index-mdx" */),
  "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-twitch-predictions-overlay-index-mdx": () => import("./../../../src/pages/blog/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/twitch-predictions-overlay/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-fields-slug-tsx-content-file-path-content-portfolio-twitch-predictions-overlay-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-blog-css-style-checkboxes-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/blog/css-style-checkboxes/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-blog-css-style-checkboxes-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-blog-golang-rest-api-embed-parameter-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/blog/golang-rest-api-embed-parameter/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-blog-golang-rest-api-embed-parameter-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-devmarks-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/devmarks/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-devmarks-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-grindlists-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/grindlists/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-grindlists-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-hackernews-clone-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/hackernews-clone/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-hackernews-clone-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-leopod-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/leopod/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-leopod-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-qr-gen-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/qr-gen/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-qr-gen-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-ship-of-harkinian-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/ship-of-harkinian/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-ship-of-harkinian-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-space-game-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/space-game/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-space-game-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-todo-vite-ts-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/todo-vite-ts/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-todo-vite-ts-index-mdx" */),
  "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-twitch-predictions-overlay-index-mdx": () => import("./../../../src/pages/portfolio/{mdx.fields__slug}.tsx?__contentFilePath=/home/chris/source/repos/portfolio-gatsby/content/portfolio/twitch-predictions-overlay/index.mdx" /* webpackChunkName: "component---src-pages-portfolio-mdx-fields-slug-tsx-content-file-path-content-portfolio-twitch-predictions-overlay-index-mdx" */)
}

